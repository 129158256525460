<template>
  <div class="col-12 g">
    <div class="card card-body">
        <div class="col-12 g col-lg-4 c">
            <div class="form-group">
              <label for="">الفترة من</label>
              <input type="text"
                class="form-control" v-model="from">
            </div>
            <div class="form-group">
              <label for="">الفترة الى</label>
              <input type="text"
                class="form-control" v-model="to">
            </div>
            <button class="btn btn-block btn-primary" @click="getNow()">
                عرض التقرير
            </button>
        </div>
        <div class="col-12 table-responsive g" v-if="orders.length">
            <h5 class="g">
                الاجمالي: 
                {{ total }} ريال (بدون الضريبة)
            </h5>
            <button class="btn btn-primary" @click="exportToExcel()">تصدير الى excel</button>
            <table class="table table-hover table-sm table-striped" id='headerTable'>
                <thead>
                    <th>
                        ID
                    </th>
                    <th>
                        ID مدرستي
                    </th>
                    <th>
                        الاسم
                    </th>
                    <th>
                        الجوال
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        المبلغ
                    </th>
                    <th>
                        المبلغ شامل الضريبة
                    </th>
                    <th>
                        الضريبة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="order in orders" :key="order._id">
                        <td>
                            {{ order.order_id }}
                        </td>
                        <td>
                            {{ order.school_id }}
                        </td>
                        <td>
                           {{ order.name }} 
                        </td>
                        <td>
                            {{ order.phone }}
                        </td>
                        <td>
                            {{ order.date }}
                        </td>
                        <td>
                            {{ order.original }}
                        </td>
                        <td>
                            {{ order.amount }}
                        </td>
                        <td>
                            {{ order.tax }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            orders: [],
            from: new Date().toISOString().split("T")[0],
            to: new Date().toISOString().split("T")[0],
            total: 0
        }
    },
    created(){
        var g = this;
    },
    methods: {
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
        },
        getNow(){
            var g = this;
            $.post(api + '/admin/stats', {
                jwt: localStorage.getItem("jwt"),
                from: this.from,
                to: this.to
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.orders = response.response
                    var total = 0
                    g.orders.forEach(function(a){
                        total = total + new Number(a.original)
                    })
                    g.total = total
                }else{
                    g.orders = []
                    alert("حدث خطأ.")
                }
            })
        }
    }
}
</script>

<style>

</style>